import ResponseConfig from "../responseConfig.js";

export default {
    headers: [
        {
            name: "Name",
            field: "name",
            visibleWhenMobile: true
        },
        {
            name: "Probability",
            field: "prob",
            visibleWhenMobile: false
        },
        {
            name: "Image",
            field: "relatedRmi.html",
            visibleWhenMobile: false,
            isHTML:true
        },

    ],
    getFieldFromPath(expression, json, key, level, result) {
        return ResponseConfig.getFieldFromPath(expression, json, key, level, result, [],{},['dtcTextId']);
    }
}


