<template>

  <div class="input-container">
    <v-row>
      <ApiInput
          v-for="(value, name) in inputsController.inputs"
          :key="name"
          :title="name"
          :label="value.label"
          :type="value.type"
          :default-value="value.value"
          :items="value.items"
          :mandatory="value.mandatory"
          :version="inputsController.inputs.apiVersion.value"
          :allowed-versions="value.versions"
          :visible="value.visible"
          :colSize="2"
          :disabled="isDemo"
          :value="value.value"
          @apiInputChange="apiInputChange"
      ></ApiInput>
    </v-row>
    <v-row>
      <v-col cols="2"></v-col>
      <v-col
          v-for="(value, name) in Object.values(inputsController.checkboxes).filter(checkbox => checkbox.versions.includes(inputsController.inputs.apiVersion.value) && checkbox.visible)"
          :key="name" cols="3">
        <div class="cb-container">
          <v-checkbox
              v-model="value.value"
              color="primary"
              :label="value.label"
              :disabled="isDemo"
          />
        </div>
      </v-col>
    </v-row>
    <v-row ref="componentCard">
      <v-col>
        <v-card
            v-if="predictionResponseReady"
            class="mx-auto"
            max-width="1000"
        >
        <div class="table-holder" v-if="predictedComponents.tableContent.length">
          <h1>Predictions</h1>
          <Table
              class="prediction-table"
              :response-table="predictedComponents"
              :is-selectable="true"
              @selectElement="selectElement"
              :index-to-filter="'relatedRmi.html'"
              :deselect-after-click="!showPredictionTable"
              :sort-by="'prob'"
          ></Table>
        </div>
          <!-- <div class="image-holder">
            <h1>Sankey Diagram</h1>
            <img v-if="featureImportance.length>0" :src="'data:image/png;base64,'+featureImportance">
            <span class="error-font-color" v-else>No image available</span>
          </div> -->

        </v-card>
      </v-col>
    </v-row>

    <v-dialog persistent
              transition="dialog-top-transition"
              max-width="1200"
              v-model="showPredictionTable"
    >
      <template>
        <v-card
        >

          <v-card-text>
            <div class="text-h5 pa-7">{{predictionHTMLTableTitle}}</div>

          </v-card-text>
          <v-card
              max-width="1100"
              class="mx-auto card-container-dialog"
          >
            <div class="prediction-dialog" v-if="predictionHTMLTable" v-html="decodedHtml(predictionHTMLTable)">

            </div>
            <div v-else class="error-font-color prediction-dialog">
              No image available
            </div>

          </v-card>
          <v-card-actions class="justify-end">
            <v-btn
                color="primary"
                text
                @click="closeDialog()"
            >Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>

import ApiInput from "../../components/Layout/ApiInput";
import Makes from "../../api/Makes";
import ECUS from "../../api/ECUS";
import ResponseConfig from "./predictionsResponseConfig";
import ResponsePredictionsConfig from "./predictionsComponentsResponseConfig.js";
import {mapGetters} from "vuex";
import InputComponent from "../mixings/InputComponent.js";
import Api from '../../api/api.js'
import ECU_Translations from "@/api/ECU_Translations";
import Table from "@/components/Layout/Table/Table.vue";

export default {
  name: "predictComponent",
  components: {
    ApiInput,
    Table
  },
  mixins: [InputComponent],
  data() {
    return {

      inputsController: {
        inputs: {
          apiVersion: {
            label: 'Api Version',
            selected: false,
            value: 'V4',
            type: "select",
            items: ['V2', 'V3', 'V4'],
            mandatory: false,
            versions: ['V2', 'V3', 'V4'],
            visible: false,
            disabled: false
          },
          language: {
            label: 'Language',
            selected: true,
            value: 'EN',
            type: "select",
            items: ['DE', 'EN'],
            mandatory: true,
            versions: ['V2', 'V3', 'V4'],
            visible: true,
            disabled: false
          },
          make: {
            label: 'Make',
            selected: false,
            value: '',
            type: "dropdown_noKeyUpUpdate",
            items: Makes,
            mandatory: true,
            versions: ['V2', 'V3'],
            visible: true,
            disabled: false
          },
          dtcReadType: {
            label: 'DTC Read Type',
            selected: false,
            value: '',
            type: "select",
            items: ['OBD', 'OE', 'OBD-J1939'],
            mandatory: false,
            versions: ['V4'],
            visible: true,
            disabled: false
          },
          vin: {
            label: 'Vin',
            selected: false,
            value: '',
            type: "input",
            items: [],
            mandatory: true,
            versions: ['V2', 'V3', 'V4'],
            visible: true,
            disabled: false
          },
          dtcs: {
            label: 'DTCs',
            selected: false,
            value: [],
            type: "multiselect",
            items: [],
            mandatory: true,
            versions: ['V2', 'V3', 'V4'],
            visible: true,
            disabled: false
          },
          ecus: {
            label: 'ECUs',
            selected: false,
            value: '',
            type: "select",
            items: ECUS,
            mandatory: false,
            versions: ['V3', 'V4'],
            visible: false,
            disabled: false,
          },
          format: {
            label: 'Format',
            selected: false,
            value: '',
            type: "select",
            items: ['HEX', 'DEC', 'PBCU'],
            mandatory: false,
            versions: ['V4'],
            visible: false,
            disabled: false,
          },
          pageId: {
            label: 'Page Id',
            selected: false,
            value: '',
            type: "number_input",
            items: [],
            mandatory: false,
            versions: ['V4'],
            visible: false,
            disabled: false,
          },

        },
        checkboxes: {

          withClassification: {label: "With classification", value: true, versions: ['V2', 'V3', 'V4'], visible: false},
          withRecommendation: {label: "With recommendation", value: true, versions: ['V2', 'V3', 'V4'], visible: false},
          allowMultipleResults: {label: "Allow multiple results", value: true, versions: ['V3', 'V4'], visible: true},
          componentPrediction: {label: "Component Prediction", value: true, versions: ['V3', 'V4'], visible: true},

        },
      },
      apiVersionPath: 'predictions',
      apiEndpoint: process.env.VUE_APP_DATA_SERVICES_API_URL,
      noECUSelected: "NO ECU",
      noFormatSelected: "NO FORMAT",
      noPageIdSelected: "NO PAGE ID",
      dtcSeparator: ' - ',
      predictedComponents: {},
      featureImportance: {},
      predictionResponseReady: false,
      showPredictionTable: false,
      predictionHTMLTable: undefined,
      predictionHTMLTableTitle:''
    };
  },
  mounted() {
    this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + this.apiVersionPath;

  },
  watch: {
    apiVersionPath(value) {
      this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + value;
    }
  },
  computed: {
    ...mapGetters(['getLoggedUser', 'getLoginStatus', 'getSessionToken']),
  },

  methods: {
    decodedHtml(base64String) {
      return atob(base64String);
    },
    closeDialog() {
      this.showPredictionTable = false;
      this.predictionHTMLTable = undefined;
    },
    selectElement(componentData) {
      this.predictionHTMLTable = componentData["relatedRmi.html"];
      this.predictionHTMLTableTitle=componentData.name+" "+componentData.prob+'%';
      this.showPredictionTable = true;


    },
    isEmptyString(value) {
      return (!value || value == '');
    },
    resetAddedValues() {
      this.inputsController.inputs.dtcs.value = [];
      this.inputsController.inputs.dtcs.value.push('');
      this.inputsController.inputs.dtcs.value.pop();
      this.inputsController.inputs.ecus.visible = false;
      this.inputsController.inputs.ecus.value = '';

      this.inputsController.inputs.format.visible = false;
      this.inputsController.inputs.format.value = '';

      this.inputsController.inputs.pageId.visible = false;
      this.inputsController.inputs.pageId.value = '';
    },
    apiInputChange(title, selected, value) {
      this.inputsController.inputs[title].selected = selected;
      this.inputsController.inputs[title].value = value;
      if (title == 'apiVersion') {

        this.inputsController.inputs.ecus.visible = false;
        this.inputsController.inputs.format.visible = false;
        this.inputsController.inputs.pageId.visible = false;
        if (value == 'V2') {
          this.apiVersionPath = 'dtcv2';
        } else if (value == 'V3') {
          this.apiVersionPath = 'dtcv3';
        } else {
          this.apiVersionPath = 'dtcv4';
        }
        this.resetAddedValues();
      } else {
        if (title == 'dtcs' || title == "ecus" || title == "format" || title == "pageId") {
          let dtcValues = this.inputsController.inputs.dtcs.value;
          let dtcItems = dtcValues.length;
          if (dtcItems > 0) {
            let dtcValues = this.inputsController.inputs.dtcs.value;
            let dtcItems = dtcValues.length;
            let dtcValue = dtcValues[dtcItems - 1].split(this.dtcSeparator);
            let finalValues = [];
            if (['V3'].includes(this.inputsController.inputs.apiVersion.value)) {
              this.inputsController.inputs.ecus.visible = true;
              if (this.isEmptyString(dtcValue[1])) {
                finalValues.push(this.dtcSeparator + this.noECUSelected);
                this.inputsController.inputs.ecus.value = '';
              } else {

                if (title != 'dtcs') {
                  if (!this.isEmptyString(this.inputsController.inputs.ecus.value)) {
                    finalValues.push(this.dtcSeparator + this.inputsController.inputs.ecus.value);
                  } else {
                    finalValues.push(this.dtcSeparator + this.noECUSelected);
                  }
                } else {
                  let ecuNotAdded = dtcValue[1].includes(this.noECUSelected);
                  if (ecuNotAdded) {
                    this.inputsController.inputs.ecus.value = '';
                    finalValues.push(this.dtcSeparator + this.noECUSelected);
                  } else {
                    this.inputsController.inputs.ecus.value = dtcValue[1];
                    finalValues.push(this.dtcSeparator + this.inputsController.inputs.ecus.value);

                  }
                }
              }
            } else if (['V4'].includes(this.inputsController.inputs.apiVersion.value)) {

              this.inputsController.inputs.ecus.visible = true;
              this.inputsController.inputs.format.visible = true;
              this.inputsController.inputs.pageId.visible = true;

              if (this.isEmptyString(dtcValue[1]) && this.isEmptyString(dtcValue[2]) && this.isEmptyString(dtcValue[3])) {
                finalValues.push(this.dtcSeparator + this.noECUSelected);
                finalValues.push(this.dtcSeparator + this.noFormatSelected);
                finalValues.push(this.dtcSeparator + this.noPageIdSelected);
                this.inputsController.inputs.ecus.value = '';
                this.inputsController.inputs.format.value = '';
                this.inputsController.inputs.pageId.value = '';
              } else {
                if (title != 'dtcs') {
                  if (!this.isEmptyString(this.inputsController.inputs.ecus.value)) {
                    finalValues.push(this.dtcSeparator + this.inputsController.inputs.ecus.value);
                  } else {
                    finalValues.push(this.dtcSeparator + this.noECUSelected);
                  }

                  if (!this.isEmptyString(this.inputsController.inputs.format.value)) {
                    finalValues.push(this.dtcSeparator + this.inputsController.inputs.format.value);
                  } else {
                    finalValues.push(this.dtcSeparator + this.noFormatSelected);
                  }

                  if (!this.isEmptyString(this.inputsController.inputs.pageId.value)) {
                    finalValues.push(this.dtcSeparator + this.inputsController.inputs.pageId.value);
                  } else {
                    finalValues.push(this.dtcSeparator + this.noPageIdSelected);
                  }
                } else {

                  let ecuNotAdded = dtcValue[1].includes(this.noECUSelected);
                  if (ecuNotAdded) {
                    this.inputsController.inputs.ecus.value = '';
                    finalValues.push(this.dtcSeparator + this.noECUSelected);
                  } else {
                    this.inputsController.inputs.ecus.value = dtcValue[1];
                    finalValues.push(this.dtcSeparator + this.inputsController.inputs.ecus.value);

                  }

                  let formatNotAdded = dtcValue[2].includes(this.noFormatSelected);
                  if (formatNotAdded) {
                    this.inputsController.inputs.format.value = '';
                    finalValues.push(this.dtcSeparator + this.noFormatSelected);
                  } else {
                    this.inputsController.inputs.format.value = dtcValue[2];
                    finalValues.push(this.dtcSeparator + this.inputsController.inputs.format.value);
                  }

                  let pageIdNotAdded = dtcValue[3].includes(this.noPageIdSelected);
                  if (pageIdNotAdded) {
                    this.inputsController.inputs.pageId.value = '';
                    finalValues.push(this.dtcSeparator + this.noPageIdSelected);
                  } else {
                    this.inputsController.inputs.pageId.value = dtcValue[3];
                    finalValues.push(this.dtcSeparator + this.inputsController.inputs.pageId.value);
                  }
                }
              }
            }

            if (['V3', 'V4'].includes(this.inputsController.inputs.apiVersion.value)) {
              if (dtcValues[dtcItems - 1] != dtcValue[0] + finalValues.join('')) {
                dtcValues[dtcItems - 1] = dtcValue[0] + finalValues.join('');
                dtcValues.push('');
                dtcValues.pop();
                this.inputsController.inputs.dtcs.value = dtcValues;
              }
            }

          } else {
            this.inputsController.inputs.ecus.visible = false;
            this.inputsController.inputs.format.visible = false;
            this.inputsController.inputs.pageId.visible = false;
          }

        }

        this.enableRequest();


      }


    }
    ,
    executeRequest() {

      let responseParsed = {
        error: false,
        errorMessage: '',
        responseTable: {},
        isSelectable: false,
        redirect: false,
      }

      if (this.isDemo) {

        responseParsed.responseTable = this.parseResponse(JSON.parse(Api.mockedDTCRequest().body), ResponseConfig);
        this.$emit('responseReady', responseParsed);
      } else {

        this.predictionResponseReady = false;
        let dtcs = this.inputsController.inputs.dtcs.value;
        let dtcCodes = [];
        for (let idx in dtcs) {

          let codeString = dtcs[idx].split(this.dtcSeparator);
          let dtcCode = {"code": codeString[0]};
          if (codeString[1] && !codeString[1].includes(this.noECUSelected)) {
            dtcCode["ecuSystem"] = ECU_Translations[codeString[1]];
          }
          if (codeString[2] && !codeString[2].includes(this.noFormatSelected)) {
            dtcCode["format"] = codeString[2];
          }
          if (codeString[3] && !codeString[3].includes(this.noPageIdSelected)) {
            dtcCode["pageId"] = codeString[3];
          }
          dtcCodes.push(dtcCode);
        }


        let body = {
          "vin": this.inputsController.inputs.vin.value,
          "dtcs": dtcCodes,
          "language": this.inputsController.inputs.language.value,
          "page": 1
        };

        if (this.inputsController.inputs.apiVersion.value == "V2") {
          body["make"] = this.inputsController.inputs.make.value;
          body["withClassification"] = this.inputsController.checkboxes.withClassification.value;
          body["withRecommendation"] = this.inputsController.checkboxes.withRecommendation.value;
        } else if (this.inputsController.inputs.apiVersion.value == "V3") {
          body["make"] = this.inputsController.inputs.make.value;
          body["allowMultipleResults"] = this.inputsController.checkboxes.allowMultipleResults.value;
          body["withClassification"] = this.inputsController.checkboxes.withClassification.value;
          body["withRecommendation"] = this.inputsController.checkboxes.withRecommendation.value;
        } else if (this.inputsController.inputs.apiVersion.value == "V4") {
          if (this.inputsController.inputs.dtcReadType.value) {
            body["dtcReadType"] = this.inputsController.inputs.dtcReadType.value;
          }
          body["allowMultipleResults"] = this.inputsController.checkboxes.allowMultipleResults.value;
          body["withClassification"] = true;
          body["withRecommendation"] = true;
          body["componentPredict"] = this.inputsController.checkboxes.componentPrediction.value;

        }


        fetch(this.apiEndpoint, {
          mode: "cors",
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'data-services-api-proxy-auth': this.getSessionToken
          },
          body: JSON.stringify({body: JSON.stringify(body)})
        }).then(
            (response) => {

              if (response.ok) {
                return response.json();
              } else {
                response.status = 500;
                response.errorMessage = 'Unknown error';
                return response;
              }
            }
        ).then(
            (data) => {


              if (data) {

                if (!this.inputsController.inputs.vin.items.includes(this.inputsController.inputs.vin.value)) {
                  this.inputsController.inputs.vin.items.push(this.inputsController.inputs.vin.value);
                }
                if (data.statusCode == 500) {
                  responseParsed.status = 500;
                  responseParsed.error = true;
                  responseParsed.errorMessage = JSON.parse(data.body).description;
                } else if (data.statusCode == 501) {
                  responseParsed.error = true;
                  responseParsed.errorMessage = data.body;
                } else if (data.statusCode == 200) {
                  this.predictionResponseReady = true;
                  const bodyParsed = JSON.parse(data.body);

                  this.predictedComponents = this.parseResponse(bodyParsed.predictedComponents, ResponsePredictionsConfig, [], [], {})
                  // this.featureImportance = bodyParsed.featureImportance.plot;
                  responseParsed.responseTable = this.parseResponse(bodyParsed.data, ResponseConfig, [], [],
                      {
                        "DTC Input": "\n",
                        "Category": "\n"
                      },
                      {
                        "effect.text": {
                          string: "Possible effects:",
                          replacement: ""
                        },
                        "cause.text": {
                          string: "Possible causes:",
                          replacement: ""
                        }
                      }
                  );


                  //  responseParsed.responseTable.tableContent=this.groupTableByDtcInputs(responseParsed.responseTable.tableContent);
                  if (responseParsed.responseTable.tableHeaders.length == 0) {
                    responseParsed.error = true;
                    responseParsed.errorMessage = 'No results found';
                  }
                } else {

                  responseParsed.error = true;
                  responseParsed.errorMessage = 'Unknown error';
                }


              }
              this.$emit('responseReady', responseParsed);
            }
        ).catch(error => {
          responseParsed.redirect = false;
          responseParsed.error = true;
          responseParsed.status = 500;
          responseParsed.errorMessage = 'There has been a problem with your fetch operation: ' + error + '. This error migth be caused because you do not have a valid session active or your IP is being whitelisted.';

          console.error('There has been a problem with your fetch operation:', error);
          this.$emit('responseReady', responseParsed);
        });
      }


    },

    groupTableByDtcInputs(tableContent) {

      let recordsPerDtcInput = {};
      const fieldId = 'dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId';

      for (let i in tableContent) {
        let row = tableContent[i];
        let DTCInput = row[fieldId];
        if (!recordsPerDtcInput[DTCInput]) {
          recordsPerDtcInput[DTCInput] = [];

        }
        recordsPerDtcInput[DTCInput].push(row);
      }

      let finalContent = []

      for (let i in recordsPerDtcInput) {

        let grouppedSynthRow = {
          fieldId: "$GROUP$ " + i
        };

        const rows = recordsPerDtcInput[i];
        if (rows.length > 1) {
          finalContent.push(grouppedSynthRow);
        }
        for (let j in rows) {
          let record = rows[j];
          finalContent.push(record);
        }
      }

      return finalContent;


    }
  }
}
</script>

<style scoped>

.input-container {
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
}

.prediction-table {
  margin-top: 1em;
  margin-bottom: 1em;

}

.prediction-dialog {
  margin: 2em;
}
.card-container-dialog{
  margin: 1em;
  padding: 1em;
}

.image-holder {
  margin: 1em;
}

.table-holder {
  padding: 1em;
}

.error-font-color {
  color: #FF5252;
}

</style>
