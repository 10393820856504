import ResponseConfig from '../responseConfig.js'

export default {
    headers: [
        {
            name: "Probablility",
            field: "probability",
            unit: '%',
            visibleWhenMobile:true

        },
        {
            name: "K-Type",
            field: "ktype",
            visibleWhenMobile:true

        },
        {
            name: "KBA",
            field: "kba",
            visibleWhenMobile:true

        },
        {
            name: "Manufacturer",
            field: "additionalInfo.manufacturer",
            visibleWhenMobile:false

        },
        {
            name: "Short Name",
            field: "additionalInfo.shortName",
            visibleWhenMobile:false

        },
        {
            name: "Engine Code",
            field: "additionalInfo.engineCode",
            visibleWhenMobile:false

        },
        {
            name: "Fuel Type",
            field: "additionalInfo.fuelType",
            visibleWhenMobile:false

        },
        {
            name: "Model Series",
            field: "additionalInfo.modelSeries",
            visibleWhenMobile:false

        },
        {
            name: "Year From",
            field: "additionalInfo.yearFrom",
            visibleWhenMobile:false

        },
        {
            name: "Year To",
            field: "additionalInfo.yearTo",
            visibleWhenMobile:false

        },
        {
            name: "CCM",
            field: "additionalInfo.ccm",
            visibleWhenMobile:false

        },
        {
            name: "KW",
            field: "additionalInfo.kw",
            unit: 'KW',
            visibleWhenMobile:false

        },
        {
            name: "Cylinder",
            field: "additionalInfo.cylinder",
            unit: 'cm3',
            visibleWhenMobile:false

        }

    ],
    getFieldFromPath(expression, json, key, level, result) {
        return ResponseConfig.getFieldFromPath(expression, json, key, level, result, [],[]);
    }
    ,
}

