import ResponseConfig from "../responseConfig.js";

export default {
    headers: [
        {
            name: "ID",
            field: "id",
            visibleWhenMobile:true
        },
        {
            name: "Schedule Order",
            field: "scheduleOrder",
            visibleWhenMobile:true
        },
        {
            name: "Schedule Title",
            field: "scheduleTitle",
            visibleWhenMobile:false
        },

        {
            name: "Schedule Type",
            field: "scheduleType",
            visibleWhenMobile:false

        },
        {
            name: "Interval Title",
            field: "intervalTitle",
            visibleWhenMobile:false

        },
        {
            name: "Mileage",
            field: "mileage.value+mileage.unit",
            visibleWhenMobile:false

        },
        {
            name: "Vehicle Age",
            field: "vehicleAge.value+vehicleAge.unit",
            visibleWhenMobile:false

        },



    ],
    getFieldFromPath(expression, json, key, level, result) {
        return ResponseConfig.getFieldFromPath(expression, json, key, level, result, [],[]);
    }
    ,
}

