import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login.vue';
import ApiTester from '../pages/ApiTester.vue';


Vue.use(VueRouter);

const routes = [

    {
        path: "/",
        name: "Login",
        component: Login,

    },
    {
        path: "/apiTester",
        name: "ApiTester",
        component: ApiTester,
        props: true,
    },


]


const router = new VueRouter({
    mode: 'history',
    routes
});


export default router
