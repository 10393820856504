export default {
    "Engine": "MO",
    "ABS": "AB",
    "Airbag": "AI",
    "Air conditioning": "KL",
    "Comfort": "KO",
    "Transmission": "GE",
    "Anti-theft device": "DI",
    "Suspension": "FA",
    "Steering": "LE",
    "Audio/Video": "AV",
    "Tire pressure monitoring": "RD",
    "OBD": "OB",
    "Safety": "SI",
    "Readiness code": "RI",
    "Gateway": "GW"

}
