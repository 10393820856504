export default [
    "Toyota",
    "OBD",
    "Fiat",
    "Audi",
    "AMC",
    "BMW",
    "Gene-Mot",
    "Daihatsu",
    "Ford",
    "Citroen",
    "Honda",
    "Barkas",
    "Mazda",
    "Peugeot",
    "Mitsubishi",
    "Subaru",
    "Hyundai",
    "DAF LKW",
    "Seat",
    "Suzuki",
    "Volvo",
    "MAN LKW",
    "DAF",
    "Iveco",
    "Skoda",
    "Talbot",
    "USFord",
    "Scania",
    "Ford LKW",
    "Mercedes LKW",
    "MB-Einbaumot.",
    "Multicar",
    "Steyr",
    "Ikarus",
    "Evobus",
    "Cadillac",
    "Kymco",
    "Harley-Davidson",
    "Husaberg",
    "Hyosung",
    "KTM",
    "Piaggio",
    "TrikeTec",
    "Triumph",
    "MV Agusta",
    "Porsche",
    "FSO",
    "Rover",
    "Trabant",
    "Neoplan",
    "Volkswagen",
    "Saab",
    "Wartburg",
    "Daewoo",
    "IFA",
    "MAN",
    "Ssangyong",
    "Volvo LKW",
    "DS Automobiles",
    "Land-Rover",
    "Lexus",
    "Alfa Romeo",
    "Lancia",
    "Opel/Vauxhall",
    "Nissan",
    "Jeep",
    "Scion",
    "Dodge",
    "Chrysler",
    "Mini",
    "Chevrolet/Daewoo",
    "Isuzu",
    "Mercedes",
    "Jaguar",
    "Infiniti",
    "Kia"
]
