<template>

    <keep-alive>
      <PredictionsInputComponent v-if="apiType=='DTC'"
                          ref="DTC"
                          :isDemo="isDemo"
                          @enableRequest="enableRequest"
                          @responseReady="responseReady"></PredictionsInputComponent>
      <VisInputsComponent v-else-if="apiType=='VIS'"
                          :isDemo="isDemo"
                          ref="VIS"
                          @enableRequest="enableRequest"
                          @responseReady="responseReady"></VisInputsComponent>
      <PinInputsComponent v-else-if="apiType=='PIN'"
                          ref="PIN"
                          :isDemo="isDemo"
                          @enableRequest="enableRequest"
                          @responseReady="responseReady"
                          @setButtonLabel="setButtonLabel"
                          @setLoadingStatus="setLoadingStatus"></PinInputsComponent>
      <InspectionsInputsComponent v-else-if="apiType=='Inspections'"
                                  ref="Inspections"
                                  :isDemo="isDemo"
                                  @enableRequest="enableRequest"
                                  @responseReady="responseReady"
                                  @setButtonLabel="setButtonLabel"
                                  @setLoadingStatus="setLoadingStatus"></InspectionsInputsComponent>
      <PredictionsInputComponent v-if="apiType=='Predict'"
                          ref="Predict"
                          :isDemo="isDemo"
                          @enableRequest="enableRequest"
                          @responseReady="responseReady"></PredictionsInputComponent>
    </keep-alive>

</template>
<script>
import VisInputsComponent from "../../inputConfig/vis/VisInputsComponent";
import PinInputsComponent from "../../inputConfig/pin/PinInputsComponent";
import InspectionsInputsComponent from "../../inputConfig/inspections/InspectionsInputsComponent";
import PredictionsInputComponent from "../../inputConfig/predictions/PredictionsInputComponent.vue";

export default {
  props: ['apiType','isDemo'],
  data() {
    return {};
  },
  components: {
    VisInputsComponent,
    PinInputsComponent,
    InspectionsInputsComponent,
    PredictionsInputComponent

  },
  name: "ComponentRenderer",
  watch: {},
  updated() {

    this.$emit('enableRequest', this.$refs[this.apiType].isRequestEnabled());
  },
  methods: {

    selectComponent(componentData) {
      this.$refs[this.apiType].selectComponent(componentData);
    },
    enableRequest(value) {
      this.$emit('enableRequest', value);
    },
    setLoadingStatus(){
      this.$emit('setLoadingStatus');

    },
    executeRequest() {
      this.$refs[this.apiType].executeRequest();
    },
    isRequestEnabled() {
     return this.$refs[this.apiType].isRequestEnabled();
    },
    responseReady(response) {
      this.$emit('responseReady', response);
    },
    setButtonLabel(label) {
      this.$emit('setButtonLabel', label);

    }


  }
}
</script>

<style scoped>
.content {
  padding-top: 1em;
  display: flex;
  justify-content: center;
}

</style>
