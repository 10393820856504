import Vue from 'vue'
import Vuex from 'vuex'
import {Auth} from 'aws-amplify';

Vue.use(Vuex)

/* eslint-disable no-new */

const loginModule = {
    state: {
        username: '',
        isLoggedIn: false,
        sessionToken: '',
        user:''
    },
    mutations: {
        setSignedIn(state, value) {
            state.isLoggedIn = value;
        },
        setUsername(state, value) {
            state.username = value;
        },
        setUser(state, value) {
            state.user = value;
        },
        setSessionToken(state, value) {
            state.sessionToken = value;
        }

    },
    getters: {
        getLoginStatus: ({isLoggedIn}) => (isLoggedIn),
        getLoggedUser: ({username}) => (username),
        getSessionToken: ({sessionToken}) => (sessionToken),

    },
    actions: {

        async signIn({commit}, {username, password}) {

            let result;
            try {

                result = await Auth.signIn({username, password,})
                    .then(user => {
                        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {

                            commit('setUsername', username);
                            commit('setUser', user);

                            return 1;
                        }
                        return 0;
                    });

                commit('setSignedIn', true);


            } catch (error) {
                console.info(error);
                return -1;
            }

            return result;
        },
        async completeNewPassword({commit, state}, {password}) {
            try {
                await Auth.completeNewPassword(
                    state.user,               // the Cognito User Object
                    password,       // the new password

                );

            } catch (error) {
                console.info(error);
                return -1;
            }
            commit('setSignedIn', true);

            return 0;
        },

        async getLoggedInfo({commit}) {


           const result= await Auth.currentSession()
                .then(async data => {
                    commit('setSessionToken', data.getIdToken().getJwtToken());
                    return 0;
                })
                .catch(async err => {
                    console.log(err);
                    return -1;
                });
                return result;
        }
    }


}

const store = new Vuex.Store({
    plugins: [],
    modules: {
        login: loginModule
    }
})

export default store
