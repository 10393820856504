export default [
    "Engine",
    "ABS",
    "Airbag",
    "Air conditioning",
    "Comfort",
    "Transmission",
    "Anti-theft device",
    "Suspension",
    "Steering",
    "Audio/Video",
    "Tire pressure monitoring",
    "OBD",
    "Safety",
    "Readiness code",
    "Gateway"
]
