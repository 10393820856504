import ResponseConfig from "../responseConfig.js";

export default {
    headers: [
        {
            name: "Error",
            field: "error.description",
            visibleWhenMobile: true
        },
        {
            name: "DTC Input",
            field: "dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId",
            visibleWhenMobile: false
        },
        {
            name: "Codes",
            field: "codes.dtcHgs+codes.dtcManuf",
            visibleWhenMobile: false
        },
        {
            name: "Classification",
            field: "classification",
            visibleWhenMobile: true
        },
        {
            name: "Category",
            field: "dtcTextId+category.text+subcategory.text",
            visibleWhenMobile: false
        },
        {
            name: "Cause",
            field: "cause.text",
            visibleWhenMobile: false
        },
        {
            name: "Information",
            field: "information.text",
            visibleWhenMobile: false
        },
        {
            name: "Effect",
            field: "effect.text",
            visibleWhenMobile: false
        },
        {
            name: "Warning",
            field: "warning.text",
            visibleWhenMobile: false
        },
        {
            name: "Page ID",
            field: "codes.pageId",
            visibleWhenMobile: false
        },
        {
            name: "Recommendation Dashboard",
            field: "recommendation.dashboard",
            visibleWhenMobile: false
        },
        {
            name: "Ecu System",
            field: "ecuSystem.text",
            visibleWhenMobile: false
        },
        {
            name: "Ecu Systems",
            field: "ecuSystems",
            visibleWhenMobile: false
        },
        {
            name: "Relevance",
            field: "relevance",
            visibleWhenMobile: false
        },

    ],
    getFieldFromPath(expression, json, key, level, result) {
        return ResponseConfig.getFieldFromPath(expression, json, key, level, result, [],{},['dtcTextId']);
    }
}


