var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"tableCard content",attrs:{"color":"secondary lighten-4"},on:{"unselectAll":_vm.unselectAll}},[_c('v-data-table',{ref:"dataTable",attrs:{"headers":_vm.renderedHeader,"items":_vm.responseTable.tableContent,"items-per-page":50,"group-by":_vm.groupBy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"item-key":"name","fixed-header":"","dense":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"13"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" "+_vm._s(items[0]['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId'])+" ")],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('TableRowDisplay',{key:index+'$$'+(item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']?item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']:''),attrs:{"id":'row_'+index+'$$'+(item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']?item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']:''),"row":item,"table-headers":_vm.responseTable.tableHeaders,"is-expanded":_vm.expandedRow==index+'$$'+(item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']?item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']:''),"is-selected":_vm.selectedRow==index+'$$'+(item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']?item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']:''),"isMobile":_vm.isMobile,"index":index+'$$'+(item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']?item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']:''),"index-to-filter":_vm.indexToFilter},on:{"selectElement":_vm.selectElement,"rowAction":_vm.rowAction}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }