import ResponseConfig from "../responseConfig.js";

export default {
    headers: [
        {
            name: "K Type",
            field: "ktypeno",
            visibleWhenMobile:true

        },
        {
            name: "System Id",
            field: "system.systemIdent",
            visibleWhenMobile:true

        },
        {
            name: "System",
            field: "system.text",
            visibleWhenMobile:true

        },
        {
            name: "Subsystem",
            field: "subSystem",
            visibleWhenMobile:false

        },
        {
            name: "Pin From",
            field: "pinFrom",
            visibleWhenMobile:false

        },
        {
            name: "Pin To",
            field: "pinTo.text",
            visibleWhenMobile:false

        },
        {
            name: "Value From",
            field: "valueFrom.unitNo+valueFrom.unitAbbreviation",
            visibleWhenMobile:false

        },
        {
            name: "Value To",
            field: "valueTo",
            visibleWhenMobile:false

        },
        {
            name: "Pin Measurement Function",
            field: "pinMeasurementFunction.text",
            visibleWhenMobile:false

        },
        {
            name: "Pin Measurement Type",
            field: "pinMeasurementType.text",
            visibleWhenMobile:false

        },
        {
            name: "Vehicle State",
            field: "vehicleState.text",
            visibleWhenMobile:false

        },
        {
            name: "Measurement Condition",
            field: "measurementCondition",
            visibleWhenMobile:false

        },
        {
            name: "Image Pin From X1",
            field: "imagePinFromX1",
            visibleWhenMobile:false

        },
        {
            name: "Image Pin From Y1",
            field: "imagePinFromY1",
            visibleWhenMobile:false

        },
        {
            name: "Image Pin From Width",
            field: "imagePinFromWidth",
            visibleWhenMobile:false

        },
        {
            name: "Image Pin From Height",
            field: "imagePinFromHeight",
            visibleWhenMobile:false

        },
        {
            name: "Image Pin To X",
            field: "imagePinToX",
            visibleWhenMobile:false

        },
        {
            name: "Image Pin To Y",
            field: "imagePinToY",
            visibleWhenMobile:false

        },
        {
            name: "Image Pin To Width",
            field: "imagePinToWidth",
            visibleWhenMobile:false

        },
        {
            name: "Image Pin To Height",
            field: "imagePinToHeight",
            visibleWhenMobile:false

        },
        {
            name: "Id Scope Image",
            field: "idScopeImage",
            visibleWhenMobile:false

        },
        {
            name: "Division X",
            field: "divisionXValue+divisionXUnit.text",
            visibleWhenMobile:false

        },
        {
            name: "Division Y",
            field: "divisionYValue+divisionYUnit.text",
            visibleWhenMobile:false

        },
        {
            name: "Offset Y Axis",
            field: "offsetYAxis",
            visibleWhenMobile:false

        },
        {
            name: "Pin Scope Coupling Type",
            field: "pinScopeCouplingType",
            visibleWhenMobile:false

        },
        {
            name: "Scope Settings Function Type",
            field: "scopeSettingsFunctionType",
            visibleWhenMobile:false

        },
        {
            name: "Component Pin From",
            field: "componentPinFrom",
            visibleWhenMobile:false

        },
        {
            name: "Component Pin To",
            field: "componentPinTo",
            visibleWhenMobile:false

        },
        {
            name: "Cable Identifier From",
            field: "cableIdentifierFrom",
            visibleWhenMobile:false

        },
        {
            name: "Cable Identifier To",
            field: "cableIdentifierTo",
            visibleWhenMobile:false

        },
        {
            name: "Year From",
            field: "yearFrom",
            visibleWhenMobile:false

        },
        {
            name: "Year To",
            field: "yearTo",
            visibleWhenMobile:false

        },
        {
            name: "Engine Code",
            field: "engineCode",
            visibleWhenMobile:false

        },

    ],
    getFieldFromPath(expression, json, key, level, result) {
        return ResponseConfig.getFieldFromPath(expression, json, key, level, result, [],[]);
    }
    ,
}

