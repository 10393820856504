export default {
    name: "InputComponent",
    props:['isDemo'],

    methods: {
        requestEnablingLogic(){
            let enable = true;
            for (let i in this.inputsController.inputs) {
                let item = this.inputsController.inputs[i];
                let isMandatory =item.mandatory;
                let selected = item.selected;
                let isInVersion=item.versions.includes(this.inputsController.inputs.apiVersion.value);
                if (isInVersion && isMandatory == true && !selected) {
                    enable = false;
                }
            }

            return enable;
        },
        isRequestEnabled(){
            return this.requestEnablingLogic();
        },
        enableRequest() {

            this.$emit('enableRequest', this.requestEnablingLogic());
        },
        parseResponse(responseBody, responseConfig, fieldsToIgnore = [], fieldsToInclude=[],concatenationMap={}, replacementMap={}) {

            let responseTable = {
                tableHeaders: [responseConfig.headers.length],
                tableContent: [],
            };
            let headerCounter = new Array(responseConfig.headers.length).fill(0);
            for (let idx in responseBody) {
                let row = {};
                let cont = 0;
                for (let i in responseConfig.headers) {
                    if (!fieldsToIgnore.includes(responseConfig.headers[i].field) && (fieldsToInclude.length==0|| fieldsToInclude.includes(responseConfig.headers[i].field)) ) {
                        let cellValue = responseConfig.getFieldFromPath(responseConfig.headers[i].field, responseBody[idx],'', 0, "");
                        if (cellValue && cellValue.length > 0 && cellValue != null) {
                            headerCounter[cont]++;
                            if (responseConfig.headers[i].unit) {
                                cellValue += " " + responseConfig.headers[i].unit;
                            }
                            responseTable.tableHeaders[cont] = {
                                text: responseConfig.headers[i].name,
                                value: responseConfig.headers[i].field,
                                visibleWhenMobile:responseConfig.headers[i].visibleWhenMobile,
                                isImage:responseConfig.headers[i].image,
                                isHTML:responseConfig.headers[i].isHTML,
                            };
                        }

                        let concatChar=concatenationMap[responseConfig.headers[i].name];
                        let textValue=concatChar? cellValue.split('$$$').join(concatChar):cellValue.split('$$$').join(' ');
                        let replacemenChar=replacementMap[responseConfig.headers[i].field];
                        textValue=replacemenChar?textValue.split(replacemenChar.string).join(replacemenChar.replacement):textValue;
                        row[responseConfig.headers[i].field] =textValue.trim();
                        cont++;
                    }
                }
                responseTable.tableContent.push(row);
            }
            return this.trimTableFromEmptyColumns(responseTable,headerCounter);

        },

        trimTableFromEmptyColumns(responseTable,headerCounter){

            let deletedHeader = 0;
            for (let headerPos in headerCounter) {
                if (headerCounter[headerPos] == 0) {
                    responseTable.tableHeaders.splice(headerPos - deletedHeader, 1);
                    for (let contentPos in responseTable.tableContent) {
                        let contCol = 0;
                        let deletedColumn = 0;
                        for (let col in responseTable.tableContent[contentPos]) {
                            if (headerPos - deletedHeader == contCol - deletedColumn) {
                                delete responseTable.tableContent[contentPos - deletedColumn][col];
                            }
                            contCol++;
                        }
                    }
                    deletedHeader++;
                }
            }

            return responseTable;
        }
    },
    watch: {
        apiVersionPath(value) {
            this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + value;
        },
    }
}
