export default {
    labels: [
        "Engine Management",
        "ABS",
    ],
    values: {
        "Engine Management": "engine_management",
        "ABS": "abs",
    }
}




