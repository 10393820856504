export default {
    getFieldFromPath(expression, json, key, level, result, fieldsToConcatenate = [], concatenateMap = {},braketsFields=[]) {
        if ((typeof json) != "object") {
            result += (braketsFields.includes(key)?'('+String(json).trim()+')':String(json).trim())+ "$$$";
        } else if (Array.isArray(json) && fieldsToConcatenate.includes(key)) {
            result = json.map(j => j[concatenateMap[key]]).join(', ').trim();
        } else {
            let expressionItems = expression.split('+');
            for (let i in expressionItems) {
                let items = expressionItems[i].split('.');
                for (let j in json) {
                    if (j == items[level]) {
                        result = this.getFieldFromPath(expressionItems[i], json[j], j, level + 1, result, fieldsToConcatenate, concatenateMap,braketsFields).trim();
                    }
                }
            }
        }
        return result;
    }
}
