import ResponseConfig from "../responseConfig.js";

export default {
    headers: [
        {
            name: "Component",
            field: "name",
            visibleWhenMobile:true

        },
        {
            name: "Id",
            field: "id",
            visibleWhenMobile:true

        },
        {
            name: "System",
            field: "systemIdent",
            visibleWhenMobile:true

        },
        {
            name: "Year From",
            field: "yearFrom",
            visibleWhenMobile:false

        },
        {
            name: "Year To",
            field: "yearTo",
            visibleWhenMobile:false

        },
        {
            name: "Engine Code",
            field: "engineCode",
            visibleWhenMobile:false

        },

    ],
    getFieldFromPath(expression, json, key, level, result) {
        return ResponseConfig.getFieldFromPath(expression, json, key, level, result, [],[]);
    }
    ,
}

