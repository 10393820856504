<template>

    <div class="input-container">
      <v-row>
        <ApiInput
            v-for="(value, name) in inputsController.inputs"
            :key="name"
            :title="name"
            :label="value.label"
            :type="value.type"
            :default-value="value.value"
            :items="value.items"
            :mandatory="value.mandatory"
            :version="inputsController.inputs.apiVersion.value"
            :allowed-versions="value.versions"
            :visible="value.visible"
            :colSize="3"
            @apiInputChange="apiInputChange"
        ></ApiInput>
      </v-row>
      <v-row>
        <v-col v-for="(value, name) in inputsController.checkboxes" :key="name">
          <div class="cb-container">
            <v-checkbox
                v-model="value.value"
                color="primary"
                :label="value.label"
                :disabled="isDemo"
            />
          </div>
        </v-col>

        <v-col :key="button.label" v-for="button in  inputsController.radioButtons">
          <span>{{ button.label }}</span>
          <v-container fluid>
            <v-radio-group v-model="button.selected"
                           row>
              <v-radio
                  v-for="(option,index) in button.options"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                  :disabled="isDemo|| (inputsController.checkboxes.singleTrustedResult.value && index==1)"
              ></v-radio>
            </v-radio-group>
          </v-container>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import ApiInput from "../../components/Layout/ApiInput";
import {mapGetters} from "vuex";
import ResponseConfig from "./visResponseConfig.js";
import InputComponent from "../mixings/InputComponent.js";
import Api from "../../api/api";

export default {
  name: "VisInputsComponent",
  components: {
    ApiInput,
  },
  mixins: [InputComponent],
  data() {
    return {
      apiVersionPath: 'vis',
      apiEndpoint: process.env.VUE_APP_DATA_SERVICES_API_URL,
      inputsController: {
        inputs: {
          apiVersion: {
            label: 'Api Version',
            selected: false,
            value: 'V1',
            type: "null",
            items: ['V1'],
            mandatory: false,
            versions: ['V1'],
            visible: false,
            disabled:false
          },
          vin: {
            label: 'Vin',
            selected: false,
            value: '',
            type: "input",
            items: [],
            mandatory: true,
            versions: ['V1'],
            visible: true,
            disabled:false
          },
          engineCode: {
            label: 'Engine Code',
            selected: false,
            value: '',
            type: "input",
            items: [],
            mandatory: false,
            versions: ['V1'],
            visible: true,
            disabled:false
          },
        },
        radioButtons: {
          resultType: {
            label: 'Result type',
            checkboxShowCondition: {
              field: '',
              value: true
            },
            options: [
              {
                label: 'KType',
                value: 1
              },
              {
                label: 'KBA (HSN/TSN)',
                value: 2
              }
            ],
            selected: 1,
          }
        },
        checkboxes: {
          singleTrustedResult: {label: "Show only 1 trusted result", value: true},
          enrichOutputVehicle: {label: "Show Additional Vehicle Info", value: true},
        },
      }
    };
  },
  mounted() {
    this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + this.apiVersionPath;
  },
  computed: {
    ...mapGetters(['getLoggedUser', 'getLoginStatus', 'getSessionToken']),
  },
  watch: {
    'inputsController.checkboxes.singleTrustedResult.value': {
      handler: function (newValue, oldValue) {

        if (newValue && !oldValue) {
          this.inputsController.radioButtons.resultType.selected = 1;
        }
      },
      deep: true
    }


  }
  ,
  methods: {

    apiInputChange(title, selected, value) {
      this.inputsController.inputs[title].selected = selected;
      this.inputsController.inputs[title].value = value;
      this.enableRequest();
    }
    ,
    executeRequest() {
      let responseParsed = {
        error: false,
        redirect:false,
        errorMessage: '',
        responseTable: {},
        isSelectable:false,

      }

      if (this.isDemo) {

        responseParsed.responseTable = this.parseResponse(JSON.parse(Api.mockedVISRequest().body), ResponseConfig);
        this.$emit('responseReady', responseParsed);
      } else {

        fetch(this.apiEndpoint, {
          mode: "cors",
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'data-services-api-proxy-auth': this.getSessionToken
          },
          body: JSON.stringify({
            "body": JSON.stringify({
              "vin": this.inputsController.inputs.vin.value,
              "engineCode": this.inputsController.inputs.engineCode.value,
              "singleTrustedResult": this.inputsController.checkboxes.singleTrustedResult.value,
              "enrichOutputVehicle": this.inputsController.checkboxes.enrichOutputVehicle.value,
              "outputVehicle": this.inputsController.radioButtons.resultType.selected
            })
          })
        }).then(
            (response) => {

              if (response.ok) {
                return response.json();
              } else {
                response.status = 500;
                response.errorMessage = 'Unknown error';
                return response;
              }
            }
        ).then(
            (data) => {
              if (data) {

                if (!this.inputsController.inputs.vin.items.includes(this.inputsController.inputs.vin.value)) {
                  this.inputsController.inputs.vin.items.push(this.inputsController.inputs.vin.value);
                }
                if (data.body == '[]') {
                  responseParsed.error = true;
                  responseParsed.errorMessage = 'No results found';
                }
                if (data.statusCode == 500) {
                  responseParsed.error = true;
                  responseParsed.errorMessage = JSON.parse(data.body).description;
                } else if (data.statusCode == 501) {
                  responseParsed.error = true;
                  responseParsed.errorMessage = data.body;
                } else if (data.statusCode == 200) {

                  responseParsed.responseTable = this.parseResponse(JSON.parse(data.body), ResponseConfig);

                } else {
                  responseParsed.error = true;
                  responseParsed.errorMessage = 'Unknown error';
                }
              }
              this.$emit('responseReady', responseParsed);
            }
        ).catch(error => {
          responseParsed.redirect=false;
          responseParsed.error = true;
          responseParsed.status = 500;
          responseParsed.errorMessage = 'There has been a problem with your fetch operation: ' + error+'. This error migth be caused because you do not have a valid session active or your IP is being whitelisted.';
          console.error('There has been a problem with your fetch operation:', error);
          this.$emit('responseReady', responseParsed);
        });
      }

    }
    ,

  }
  ,
}

</script>

<style scoped>
.input-container {
  margin-left: 1em;
  margin-rigth: 1em;
  margin-top: 1em;
}

.inputs-card {
  margin-left: 1em;
  margin-right: 1em;
}
</style>
