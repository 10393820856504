<template>
  <tr     ref="cardStart" >
    <td>
      <v-card
          @click="rowAction()"
          class="mx-auto"
          max-width="400"
      >
        <v-list class="transparent"

        >
          <v-list-item class="cardItem"
              v-for="(item,index) in tableHeaders"
              :key="index"
          >
            <v-list-item-title class="cardHeader">{{ item.text}}</v-list-item-title>
            <v-list-item-subtitle class="text-right">
              <div class="dataCell"
                   :class="{iconCell:item.value=='classification'}"
                   :style="{'height':Math.max(Math.round(String( row[item.value]).length/35)*35, 100)+'px'}"
              >
              <span
                  v-if="item.value=='classification'">
                <v-icon v-if="row[item.value]=='critical'" color="error">mdi-alert-circle</v-icon>
                <v-icon v-if="row[item.value]=='warning'" color="warning">mdi-alert</v-icon>
                 {{ row[item.value]}}
              </span>
                <span v-else-if="typeof row[item.value] === 'number'">
               {{ row[item.value] }}
              </span>
                <span v-else>

                {{ row[item.value].split('\\n').join('\n') }}
              </span>
              </div>
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>

      </v-card>
    </td>
  </tr>
</template>

<script>
import TableElement from "../mixings/TableElement";

export default {
  props: [ 'row', 'tableHeaders'],
  mixins: [TableElement],
  name: "TableCard",
  mounted() {
    const element = this.$refs.cardStart;
    const top = element.offsetTop+400;
    window.scrollTo(0, top);
  }
}
</script>

<style scoped>
.cardHeader{
  align-self: start;
}
.cardItem{
  align-items: start;

}
.dataCell {
  margin: 0.8em;
  margin-top: 5px;
  margin-rigth: 5px;
  text-align: left;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  transition: height 0.5s;
  white-space: pre-line;

}
.iconCell {
  text-align: left;
  text-transform: capitalize;
  min-width: 7em;
}
</style>
