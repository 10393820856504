<template>
  <v-card

      class="tableCard content"
      color="secondary lighten-4"
      @unselectAll="unselectAll">
    <v-data-table
        ref="dataTable"
        :headers=" renderedHeader"
        :items=" responseTable.tableContent"
        :items-per-page="50"
        :group-by="groupBy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        item-key="name"
        fixed-header
        dense
    >
      <template v-slot:group.header="{items, isOpen, toggle}">
        <th colspan="13">
          <v-icon @click="toggle"
          >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          {{ items[0]['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId'] }}
        </th>
      </template>
      <template v-slot:item="{ item,index }">

        <TableRowDisplay
            :key="index+'$$'+(item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']?item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']:'')"
            :id="'row_'+index+'$$'+(item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']?item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']:'')"
            :row="item"
            :table-headers="responseTable.tableHeaders"
            :is-expanded="expandedRow==index+'$$'+(item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']?item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']:'')"
            :is-selected="selectedRow==index+'$$'+(item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']?item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']:'')"
            :isMobile="isMobile"
            :index="index+'$$'+(item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']?item['dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId']:'')"
            @selectElement="selectElement"
            @rowAction="rowAction"
            :index-to-filter="indexToFilter"
        >
        </TableRowDisplay>

      </template>


    </v-data-table>
  </v-card>
</template>
<script>
import TableRowDisplay from "./TableRowDisplay";

export default {
  name: "Table",
  components: {TableRowDisplay},
  props: ['responseTable', 'isSelectable', 'groupBy', 'sortBy', 'indexToFilter','deselectAfterClick'],
  created() {
    window.addEventListener("resize", this.screenResized);
  },
  destroyed() {
    window.removeEventListener("resize", this.screenResized);
  },
  beforeMount() {

    this.screenResized({target: window});

  },
  mounted() {
  },
  data() {
    return {
      singleExpand: false,
      isMobile: null,

      sortDesc: true,
      expandedRow: -1,
      selectedRow: -1
    };
  },
  watch: {
    deselectAfterClick: function (newVal, oldVal) { // watch it

      if (newVal && !oldVal) {
        this.selectedRow=-1;
      }
    }
  },
  computed: {
    renderedHeader() {


      let renderedHeader = []
      if (this.isMobile && !this.isExpanded) {
        for (let idx in this.responseTable.tableHeaders) {
          let header = this.responseTable.tableHeaders[idx];
          if (header.visibleWhenMobile) {
            renderedHeader.push(header);
          }
        }
      } else {
        for (let idx in this.responseTable.tableHeaders) {
          let header = this.responseTable.tableHeaders[idx];
          if (!header.isHTML) {
            renderedHeader.push(header);
          }

        }
      }


      return renderedHeader;
    },
  },
  methods: {

    unselectAll() {
      if (
          !this.isSelectable
      ) {
        this.selectedRow = -1;
      }
    },

    screenResized(e) {
      if (e.target.outerWidth <= 760) {
        this.isMobile = true;
      } else {
        this.isMobile = false;

      }
    },

    rowAction(index) {
      if (this.isSelectable) {
        this.selectedRow = index;

      } else {
        if (this.expandedRow == index) {
          this.expandedRow = -1;
        } else {
          this.expandedRow = index;
        }

      }

    } ,

    selectElement(index) {

      if (this.isSelectable) {

        let idx = this.isNumeric(index) ?index:index.split('$$')[0];
        this.$emit('selectElement', this.responseTable.tableContent[idx]);

      }
    } ,
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

  }
}
</script>

<style scoped>


.tableCard {
  max-width: 100%;
  margin: 1em;
}

.selected td {
  background: #71C791;
}

</style>
