<template>
  <v-container class="dashboard">
    <v-card elevation="2">
      <v-banner v-if="apiTabConfiguration[currentApi].apiBanner.show">
        {{ apiTabConfiguration[currentApi].apiBanner.text }}

        <template v-slot:actions>
          <v-btn
              text
              color="primary"
              @click="dismissBanner"
          >
            Dismiss
          </v-btn>
        </template>
      </v-banner>
    </v-card>
    <v-toolbar
        color="primary"
        dark
        flat>
      <template >
        <v-tabs fixed-tabs>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab
              v-for="tab in tabs"
              :key="tab.value"
              @click="changeTab(tab.value)">
            {{ tab.label }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card elevation="2">
      <v-row class="fill-height">
        <v-col cols="1"></v-col>
        <v-col cols="2">
          <v-container class="no-padding-countainer">
            <v-checkbox
                v-model="demo"
                color="primary"
                label="Demo mode"
            />
          </v-container>
        </v-col>
      </v-row>
      <v-form class="content-form" ref="form" v-on:submit.prevent>
        <v-card elevation="2"
                class="inputs-card">
          <FieldsComponentsRenderer
              :apiType="currentApi"
              ref="paramHolder"
              @enableRequest="enableRequest"
              @responseReady="responseReady"
              @setButtonLabel="setButtonLabel"
              @setLoadingStatus="setLoadingStatus"
              :isDemo="demo"
          >
          </FieldsComponentsRenderer>
          <v-row>
            <v-col cols="4"></v-col>
            <v-col cols="4">
              <div class="button-container">
                <v-btn
                    ref="requestButton"
                    color="primary"
                    @click="executeRequest"
                    :disabled="!buttonVisible"
                    class="form-button"
                    type="submit"
                >
                <span
                    class="mdi mdi-eye"
                ></span>
                  {{ apiTabConfiguration[currentApi].buttonLabel }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-container class="content-form">

          <v-row ref="loading">
            <v-col cols="5"></v-col>
            <v-col cols="3">
              <v-progress-circular
                  v-if="loading"
                  :size="100"
                  :width="5"
                  indeterminate
                  color="primary">
              </v-progress-circular>
              <v-alert
                  v-else-if="error"
                  type="error">
              <span v-if="!errorMessage">
                Error sending the request!</span>
                <span v-else>
                {{ errorMessage }}</span>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
        <Table v-if="showTable" :response-table="responseTable"
               :is-selectable="apiTabConfiguration[currentApi].apiTables.isSelectable"
               @selectElement="selectElement"
               :group-by="apiTabConfiguration[currentApi].apiTables.groupBy"
               :sort-by="apiTabConfiguration[currentApi].apiTables.sortBy"
               :index-to-filter="apiTabConfiguration[currentApi].apiTables.groupBy"
        ></Table>

      </v-form>
    </v-card>
  </v-container>
</template>
<script>

import FieldsComponentsRenderer from "./FieldsComponentRenderer";
import Table from "./Table/Table.vue";
import {mapGetters} from "vuex";
import {Auth} from 'aws-amplify';

export default {
  name: "AppContent",
  props: {
    apiType: String
  },
  components: {
    FieldsComponentsRenderer,
    Table,
  },
  async beforeMount() {
    const isLoggedResult = await this.$store.dispatch('getLoggedInfo');
    if (isLoggedResult == -1) {
      await this.$router.push({name: 'Login'});
    }
  },
  mounted() {
    this.currentApi = this.apiType;
    if (!this.currentApi) {
      this.currentApi = 'DTC';
    }
  },
  data() {
    return {
      currentApi: 'DTC',
      showTable: false,
      error: false,
      errorMessage: '',
      loading: false,
      requestIsEnbled: false,
      tabs: [{label: 'Trouble Code Interpretations (DTC API)', value: 'DTC'}, {label: 'VIS', value: 'VIS'}, {label: 'PIN', value: 'PIN'}, {label: 'Inspections', value: 'Inspections'}],

      apiTabConfiguration: {
        DTC: {
          apiTables: {
            isSelectable: false,
            groupBy: 'dtcInput.code+dtcInput.ecuSystem+dtcInput.format+dtcInput.pageId',
            indexToFilter: 0,
            sortBy: 'relevance'
          },
          buttonLabel: "Request DTC data",
          apiBanner: {
            show: false,
            text: '',
          },

        },
        VIS: {
          apiTables: {
            isSelectable: false,
            groupBy: '',
            indexToFilter: -1,
            sortBy: ''
          },
          buttonLabel: "Predict Vehicle",
          apiBanner: {
            show: false,
            text: '',
          }
        },
        PIN: {
          apiTables: {
            isSelectable: true,
            groupBy: '',
            indexToFilter: -1,
            sortBy: ''
          },
          buttonLabel: 'Check Component Availability',
          apiBanner: {
            show: false,
            text: 'Work in progres! This tab is not fully functional',
          }
        },
        Inspections: {
          apiTables: {
            isSelectable: true,
            groupBy: '',
            indexToFilter: -1,
            sortBy: ''
          },
          buttonLabel: "Get services",
          apiBanner: {
            show: false,
            text: 'Work in progres! This tab is not fully functional',
          }
        },
        Predict: {
          apiTables: {
            isSelectable: false,
            groupBy: '',
            indexToFilter: -1,
            sortBy: ''
          },
          buttonLabel: "Get Predictions",
          apiBanner: {
            show: false,
            text: 'Work in progres! This tab is not fully functional',
          }
        },
      },
      responseTable: {
        tableHeaders: [],
        tableContent: [],
      },
      demo: false,
    };
  },
  computed: {
    ...mapGetters(['getLoggedUser', 'getLoginStatus', 'getSessionToken']),
    buttonVisible() {
      if (this.demo) {
        return true;
      } else if (this.requestIsEnbled) {

        return true;
      }
      return false;
    },
  },
  methods: {
    setButtonLabel(label) {
      this.apiTabConfiguration[this.currentApi].buttonLabel = label;
    },
    selectElement(elementData) {
      this.$refs.paramHolder.selectComponent(elementData);
    },
    dismissBanner() {
      this.apiTabConfiguration[this.currentApi].apiBanner.show = false;
    },
    setLoadingStatus() {
      const element = this.$refs.loading;
      const top = element.offsetTop;
      window.scrollTo(0, top);
      this.showTable = false;
      this.loading = true;
    },
    responseReady(response) {
      this.loading = false;
      if (response.error) {
        this.showTable = false;
        this.error = true;
        this.errorMessage = response.errorMessage;
        if (response.redirect) {
          this.$router.push({name: 'Login'});
        }
      } else if (response.hideTable) {
        this.showTable = false;
        this.error = false;
        this.responseTable = response.responseTable;
      } else {
        this.showTable = true;
        this.error = false;
        this.responseTable = response.responseTable;
        this.apiTabConfiguration[this.currentApi].apiTables.isSelectable = response.isSelectable;

      }
    },
    enableRequest(value) {

      this.requestIsEnbled = value;
    },
    changeTab(event) {

      if (event != this.currentApi) {
        this.currentApi = event;
        this.showTable = false;
      }
    },
    refreshInputs() {
      return true;
    },
    async executeRequest() {

      Auth.currentSession()
          .catch(() => {
            this.$router.push({name: 'Login'});
          });

      if (this.requestIsEnbled || this.demo) {
        this.showTable = false;
        this.loading = true;
        this.error = false;
        this.errorMessage = '';
        this.$refs.paramHolder.executeRequest();
      }
    },
  }
  ,
}
;

</script>
<style scoped>
.content-form {
  padding: 1em;
}

.button-container {
  text-align: right;
}

.form-button {
  margin: 1em;
}

.request-button span {
  padding: 10px;
}

.dashboard {
  margin-top: 3em;
  margin-bottom: 5em;
  max-width: 100em;
}

.no-padding-countainer {
  padding: 0px;
}


</style>
