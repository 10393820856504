<template>
  <TableCard v-if="renderedHeader.length>0 && isExpanded && isMobile"
             :row="renderedRow"
             :table-headers="renderedHeader"
             :is-expanded="isExpanded"
             :is-selected="isSelected"
             @rowAction="rowAction"
             :index="index"></TableCard>
  <TableRow
      v-else-if="renderedHeader.length>0"
      :expanded="isExpanded"
      :row="renderedRow"
      :table-headers="renderedHeader"
      :is-expanded="isExpanded"
      :is-selected="isSelected"
      @selectElement="selectElement"
      @rowAction="rowAction"
      :index="index"
      :index-to-filter="indexToFilter"
  ></TableRow>
</template>

<script>
import TableCard from "./TableCard";
import TableRow from "./TableRow";
import TableElement from "../mixings/TableElement";

export default {
  props: ['isExpanded', 'isSelected', 'isMobile', 'row', 'tableHeaders','indexToFilter'],
  mixins: [TableElement],
  name: "TableRowDisplay",
  components: {TableRow, TableCard},
  mounted() {

  },

  methods: {
    selectElement(index) {
      this.$emit('selectElement', index);
    },
  },
  computed: {

    renderedHeader() {
      let renderedHeader = []
      if (this.isMobile && !this.isExpanded) {
        for (let idx in this.tableHeaders) {
          let header = this.tableHeaders[idx];
          if (header.visibleWhenMobile) {
            renderedHeader.push(header);
          }
        }
      } else {
        renderedHeader = JSON.parse(JSON.stringify(this.tableHeaders));
      }

      return renderedHeader;
    },
    renderedRow() {
      let renderedRow = [];
      if (this.isMobile && !this.isExpanded) {

        for (let idx in this.tableHeaders) {
          let header = this.tableHeaders[idx];
          if (header.visibleWhenMobile) {
            renderedRow.push(this.row[header.value]);
          }
        }
      } else {
        renderedRow = JSON.parse(JSON.stringify(this.row));
      }
      return renderedRow;
    }

  }

}
</script>

<style scoped>

</style>
