<template>

    <div class="input-container">
      <v-row>
        <v-col :key="button.label" v-for="button in  inputsController.radioButtons">
          <span>{{ button.label }}</span>

          <v-radio-group v-model="button.selected"
                         row>
            <v-radio
                v-for="(option) in button.options"
                :key="option.value"
                :label="option.label"
                :value="option.value"
                :disabled="isDemo"
            ></v-radio>
          </v-radio-group>

        </v-col>
      </v-row>
      <v-row>
        <ApiInput
            v-for="(value, name) in inputsController.inputs"
            :key="name"
            :title="name"
            :label="value.label"
            :type="value.type"
            :default-value="value.value"
            :items="value.items"
            :mandatory="value.mandatory"
            :version="inputsController.inputs.apiVersion.value"
            :allowed-versions="value.versions"
            :visible="value.visible"
            :colSize="2"
            @apiInputChange="apiInputChange"
            :disabled="isDemo"

        ></ApiInput>
      </v-row>


      <v-row ref="componentCard">
        <v-col>
          <v-card v-if="isServiceSelected"

                  class="mx-auto"
                  max-width="400"
          >
            <v-row>
              <v-col cols="8">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5">
                      {{ selectedService.id.value }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-card-actions>
                  <v-btn
                      text
                      color="alertBlue"
                      @click="dismissComponentSelection"
                  >
                    Dismiss
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>

            <v-list class="transparent">
              <v-list-item
                  v-for="item in selectedService"
                  :key="item.label"
              >
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ item.value }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-btn
                  text
                  color="primary"
                  @click="executeRequestForService"
              >
                Get Service Details
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="executeRequestForParts"
              >
                Get Parts
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

</template>

<script>

import ApiInput from "../../components/Layout/ApiInput";
import ServicesResponseConfig from "./inspectionsResponseConfig";

import {mapGetters} from "vuex";
import InputComponent from "../mixings/InputComponent.js";
import Api from '../../api/api.js'

export default {
  name: "inspectionsInputsComponent",
  components: {
    ApiInput,
  },
  mixins: [InputComponent],
  data() {
    return {
      apiVersionPath: 'inspections',
      apiEndpoint: process.env.VUE_APP_DATA_SERVICES_API_URL,
      isServiceSelected: false,
      selectedService: {
        id: {
          label: 'Id',
          value: '',
        },
        intervalTitle: {
          label: 'Interval Title',
          value: '',
        },
        scheduleTitle: {
          label: 'Schedule Title',
          value: '',
        },
      },
      inputsController: {
        inputs: {
          apiVersion: {
            label: 'Api Version',
            selected: true,
            value: 'V1',
            type: "select",
            items: ['V1'],
            mandatory: false,
            versions: ['V1'],
            visible: false,
            disabled: false
          },
          ktype: {
            label: 'KType',
            selected: false,
            value: '',
            type: "input",
            items: [],
            mandatory: true,
            versions: ['V1'],
            visible: true,
            disabled: false
          },
          vin: {
            label: 'Vin',
            selected: false,
            value: '',
            type: "input",
            items: [],
            mandatory: true,
            versions: ['V1'],
            visible: true,
            disabled: false
          },
          year: {
            label: 'Year',
            selected: false,
            value: '',
            type: "input",
            items: [],
            mandatory: true,
            versions: ['V1'],
            visible: true,
            disabled: false
          },
          language: {
            label: 'Language',
            selected: false,
            value: '',
            type: "dropdown",
            items: ['DE', 'EN','NL'],
            mandatory: true,
            versions: ['V1'],
            visible: true,
            disabled: false
          },
          mileage: {
            label: 'Mileage',
            selected: false,
            value: '',
            type: "input",
            items: [],
            mandatory: false,
            versions: ['V1'],
            visible: false,
            disabled: false
          },
          region: {
            label: 'Region',
            selected: false,
            value: '',
            type: "input",
            items: [],
            mandatory: false,
            versions: ['V1'],
            visible: false,
            disabled: false
          },
        },
        radioButtons: {
          service: {
            label: 'Service:',
            checkboxShowCondition: {
              field: '',
              value: true
            },
            options: [
              {
                label: 'All Inspections',
                value: 1
              },
              {
                label: 'Next inspection',
                value: 2
              },
            ],
            selected: 1,
          }
        },
      },
    };
  },
  mounted() {
    this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + this.apiVersionPath;
  },
  watch: {
    'inputsController.radioButtons.service.selected': {
      handler: function (newValue) {
        if (newValue == 2) {
          this.inputsController.inputs.mileage.visible = true;
          this.inputsController.inputs.region.visible = true;
        } else {
          this.inputsController.inputs.mileage.visible = false;
          this.inputsController.inputs.region.visible = false;
        }
      },
      deep: true
    }


  },
  computed: {
    ...mapGetters(['getLoggedUser', 'getLoginStatus', 'getSessionToken']),
  },

  methods: {
    selectComponent(componentData) {
      const element = this.$refs.componentCard;
      const top = element.offsetTop;
      window.scrollTo(0, top);

      this.isServiceSelected = true;
      for (let i in componentData) {

        this.selectedService[i].value = componentData[i];

      }
    },
    dismissComponentSelection() {
      this.isServiceSelected = false;
      let responseParsed = {
        error: false,
        errorMessage: '',
        responseTable: {},
        hideTable: true,
      }
      this.$emit('responseReady', responseParsed);
    },
    apiInputChange(title, selected, value) {
      this.inputsController.inputs[title].selected = selected;
      this.inputsController.inputs[title].value = value;
      this.enableRequest();
    },
    executeRequest() {

      let responseParsed = {
        error: false,
        errorMessage: '',
        responseTable: {},
      }


      if (this.isDemo) {

        // responseParsed.responseTable =
        switch (this.inputsController.radioButtons.service.selected) {
          case 1:
            responseParsed.responseTable = this.parseResponse(JSON.parse(Api.mockedInspectionsRequest().body), ServicesResponseConfig, [], ["id", "intervalTitle", "scheduleTitle"]);
            responseParsed.isSelectable = true;
            break;
          case 2:
            responseParsed.responseTable = this.parseResponse(JSON.parse(Api.mockedInspectionsRequest().body), ServicesResponseConfig, [], ["id", "intervalTitle", "scheduleTitle"]);
            responseParsed.isSelectable = true;

            break;

        }

        this.$emit('responseReady', responseParsed);
      } else {

        let requestBody = {
          "ktype": this.inputsController.inputs.ktype.value,
          "vin": this.inputsController.inputs.vin.value,
          "language": this.inputsController.inputs.language.value,
          "year": this.inputsController.inputs.year.value,


        };
        switch (this.inputsController.radioButtons.service.selected) {
          case 1:
            requestBody.requestType = 'all';
            break;
          case 2:
            requestBody.requestType = 'upcoming';
            requestBody.mileage = this.inputsController.inputs.mileage.value;
            requestBody.region = this.inputsController.inputs.region.value;
            break;
          case 3:
            requestBody.requestType = 'parts';

            break;
        }


        fetch(this.apiEndpoint, {
          mode: "cors",
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'data-services-api-proxy-auth': this.getSessionToken
          },
          body: JSON.stringify({
            "body": JSON.stringify(requestBody)
          })

        }).then(
            (response) => {

              if (response.ok) {
                return response.json();
              } else {
                response.status = 500;
                response.errorMessage = 'Unknown error';
                return response;
              }
            }
        ).then(
            (data) => {

              if (data) {

                if (data.statusCode == 500) {
                  responseParsed.status = 500;
                  responseParsed.error = true;
                  responseParsed.errorMessage = JSON.parse(data.body).description;
                } else if (data.statusCode == 501) {
                  responseParsed.error = true;
                  responseParsed.errorMessage = data.body;
                } else if (data.statusCode == 200) {
                  switch (this.inputsController.radioButtons.service.selected) {
                    case 1:
                      responseParsed.responseTable = this.parseResponse(JSON.parse(data.body), ServicesResponseConfig, [], ["id", "intervalTitle", "scheduleTitle"]);
                      responseParsed.isSelectable = true;

                      break;
                    case 2:

                      responseParsed.responseTable = this.parseResponse(JSON.parse(data.body), ServicesResponseConfig, [], ["id", "intervalTitle", "scheduleTitle"]);
                      responseParsed.isSelectable = true;

                      break;

                  }


                } else {

                  responseParsed.error = true;
                  responseParsed.errorMessage = 'Unknown error';
                }


              }
              this.$emit('responseReady', responseParsed);
            }
        ).catch(error => {
          responseParsed.redirect=false;
          responseParsed.error = true;
          responseParsed.status = 500;
          responseParsed.errorMessage = 'There has been a problem with your fetch operation: ' + error+'. This error migth be caused because you do not have a valid session active or your IP is being whitelisted.';

          console.error('There has been a problem with your fetch operation:', error);
          this.$emit('responseReady', responseParsed);
        });
      }
    },
    executeRequestForService() {

      let responseParsed = {
        error: false,
        errorMessage: '',
        responseTable: {},
      }


      if (this.isDemo) {

        responseParsed.responseTable = this.generateCategoryTableFromInspectionCategories(JSON.parse(Api.mockedInspectionsRequest().body)[0].inspectionCategories);
        this.$emit('responseReady', responseParsed);
      } else {

        this.$emit('setLoadingStatus');
        let requestBody = {
          "ktype": this.inputsController.inputs.ktype.value,
          "vin": this.inputsController.inputs.vin.value,
          "language": this.inputsController.inputs.language.value,
          "year": this.inputsController.inputs.year.value,
          "id": this.selectedService.id.value,
          "requestType": 'withID',
        };

        if (this.inputsController.radioButtons.service.selected == 2) {

          requestBody.mileage = this.inputsController.inputs.mileage.value;
          requestBody.region = this.inputsController.inputs.region.value;

        }


        fetch(this.apiEndpoint, {
          mode: "cors",
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'data-services-api-proxy-auth': this.getSessionToken
          },
          body: JSON.stringify({
            "body": JSON.stringify(requestBody)
          })

        }).then(
            (response) => {

              if (response.ok) {
                return response.json();
              } else {
                response.status = 500;
                response.errorMessage = 'Unknown error';
                return response;
              }
            }
        ).then(
            (data) => {

              if (data) {

                if (data.statusCode == 500) {
                  responseParsed.status = 500;
                  responseParsed.error = true;
                  responseParsed.errorMessage = JSON.parse(data.body).description;
                } else if (data.statusCode == 501) {
                  responseParsed.error = true;
                  responseParsed.errorMessage = data.body;
                } else if (data.statusCode == 200) {
                  responseParsed.responseTable = this.generateCategoryTableFromInspectionCategories(JSON.parse(data.body)[0].inspectionCategories);

                } else {

                  responseParsed.error = true;
                  responseParsed.errorMessage = 'Unknown error';
                }


              }
              this.$emit('responseReady', responseParsed);
            }
        ).catch(error => {
          responseParsed.redirect=false;
          responseParsed.error = true;
          responseParsed.status = 500;
          responseParsed.errorMessage = 'There has been a problem with your fetch operation: ' + error+'. This error migth be caused because you do not have a valid session active or your IP is being whitelisted.';

          console.error('There has been a problem with your fetch operation:', error);
          this.$emit('responseReady', responseParsed);
        });
      }
    },
    executeRequestForParts() {

      let responseParsed = {
        error: false,
        errorMessage: '',
        responseTable: {},
      }


      if (this.isDemo) {

        responseParsed.responseTable = this.generateTaskTableFromInspectionCategoriesTasks(JSON.parse(Api.mockedInspectionsRequest().body));
        this.$emit('responseReady', responseParsed);
      } else {

        this.$emit('setLoadingStatus');
        let requestBody = {
          "ktype": this.inputsController.inputs.ktype.value,
          "vin": this.inputsController.inputs.vin.value,
          "language": this.inputsController.inputs.language.value,
          "year": this.inputsController.inputs.year.value,
          "id": this.selectedService.id.value,
          "requestType": 'withID',
        };

        if (this.inputsController.radioButtons.service.selected == 2) {

          requestBody.mileage = this.inputsController.inputs.mileage.value;
          requestBody.region = this.inputsController.inputs.region.value;

        }


        fetch(this.apiEndpoint, {
          mode: "cors",
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'data-services-api-proxy-auth': this.getSessionToken
          },
          body: JSON.stringify({
            "body": JSON.stringify(requestBody)
          })

        }).then(
            (response) => {

              if (response.ok) {
                return response.json();
              } else {
                response.status = 500;
                response.errorMessage = 'Unknown error';
                return response;
              }
            }
        ).then(
            (data) => {

              if (data) {

                if (data.statusCode == 500) {
                  responseParsed.status = 500;
                  responseParsed.error = true;
                  responseParsed.errorMessage = JSON.parse(data.body).description;
                } else if (data.statusCode == 501) {
                  responseParsed.error = true;
                  responseParsed.errorMessage = data.body;
                } else if (data.statusCode == 200) {
                  responseParsed.responseTable = this.generateTaskTableFromInspectionCategoriesTasks(JSON.parse(data.body));

                } else {

                  responseParsed.error = true;
                  responseParsed.errorMessage = 'Unknown error';
                }


              }
              this.$emit('responseReady', responseParsed);
            }
        ).catch(error => {
          responseParsed.error = false;
          responseParsed.status = 500;
          responseParsed.redirect=true;

          responseParsed.errorMessage = 'There has been a problem with your fetch operation: ' + error+'. This error migth be caused because you do not have a valid session active or your IP is being whitelisted.';

          console.error('There has been a problem with your fetch operation:', error);
          this.$emit('responseReady', responseParsed);
        });
      }
    },
    generateCategoryTableFromInspectionCategories(inspectionCategories) {
      let headers = [
        {
          text: "Cetegory Order",
          value: "categoryOrder",
          visibleWhenMobile:true

        },
        {
          text: "Category Title",
          value: "categoryTitle",
          visibleWhenMobile:true

        },
        {
          text: "Tasks",
          value: "inspectionTasks",
          visibleWhenMobile:true

        }
      ];

      let responseTable = {
        tableHeaders: headers,
        tableContent: [],
      };
      let headerCounter = new Array(headers.length).fill(0);

      for (let idx in inspectionCategories) {
        let category = inspectionCategories[idx];
        let row = {};
        let cont = 0;
        for (let catField in category) {

          let field = '';
          if (catField != 'inspectionTasks') {
            headerCounter[cont]++;

            field = category[catField]
          } else {
            field = '';
            for (let taskIdx in category[catField]) {
              let task = category[catField][taskIdx];
              field += task.taskOrder + " " + task.taskTitle + " " + (task.taskTitleExtended ? task.taskTitleExtended : '') + "\n";
              headerCounter[cont]++;
            }
          }

          row[catField] = field;
          cont++;
        }
        responseTable.tableContent.push(row);
      }
      return this.trimTableFromEmptyColumns(responseTable, headerCounter);
    },
    generateTaskTableFromInspectionCategoriesTasks(inspectionResponse) {

      let headers = [];

      for (let i in ServicesResponseConfig.headers) {
        headers.push(
            {
              text: ServicesResponseConfig.headers[i].name,
              value: ServicesResponseConfig.headers[i].field,
              visibleWhenMobile:ServicesResponseConfig.headers[i].visibleWhenMobile,

            },
        );
      }
      headers.push(
          {
            text: "Category",
            value: "category"
          },
      );
      headers.push(
          {
            text: "Inspection Task",
            value: "inspectionTask"
          },
      );
      let responseTable = {
        tableHeaders: headers,
        tableContent: [],
      };

      for (let idx in inspectionResponse) {
        let inspection = inspectionResponse[idx];



        let categories = inspection.inspectionCategories;
        for (let categoryIdx in categories) {

          let category = categories[categoryIdx];

          let tasks = category.inspectionTasks;
          for (let taskIdx in tasks) {
            let row = {};
            let task = tasks[taskIdx];
            let genericArticleNumbers = '';
            for (let n in task.genericArticleNumbers) {
              genericArticleNumbers += task.genericArticleNumbers[n];

              if (n < task.genericArticleNumbers.length-1) {
                genericArticleNumbers += ",";
              }
            }
            let field = task.taskOrder + " " + task.taskTitle + " " + (task.askTitleExtended ? task.taskTitleExtended : '') + "(" + genericArticleNumbers + ")" + "\r\n";

            for (let inspectionIdx in inspection) {
              if (!['language', 'ktype'].includes(inspectionIdx)) {
                if (inspectionIdx == 'vehicleAge') {
                  row["vehicleAge.value+vehicleAge.unit"] = inspection[inspectionIdx].value + " " + inspection[inspectionIdx].unit;
                } else if (inspectionIdx == 'mileage') {
                  row["mileage.value+mileage.unit"] = inspection[inspectionIdx].value + " " + inspection[inspectionIdx].unit;
                } else if (inspectionIdx != 'inspectionCategories') {
                  row[inspectionIdx] = inspection[inspectionIdx];
                }
              }

            }
            if (! row["vehicleAge.value+vehicleAge.unit"] ) {
              row["vehicleAge.value+vehicleAge.unit"] = '';
            }
            if (! row["mileage.value+mileage.unit"] ) {
              row["mileage.value+mileage.unit"] = '';
            }
            row['category'] = category.categoryOrder + " - " + category.categoryTitle;

            row['inspectionTask'] = field;
            responseTable.tableContent.push(row);
          }

        }

      }

      return responseTable;
    }


  }


}
</script>

<style scoped>
.addButon {
  cursor: pointer;
}

.addButon:hover {
  color: #1D86D9 !important;
}

.input-container {
  margin-left: 1em;
  margin-rigth: 1em;
  margin-top: 1em;
  padding-bottom: 2em;
}

.inputs-card {
  margin-left: 1em;
  margin-right: 1em;
}
</style>
