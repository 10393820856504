import Vue from 'vue'
import App from './App.vue'
import Vuetify from './plugins/vuetify.js'
import VueMeta from 'vue-meta';
import router from './router'
import store from './store'
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

Vue.config.productionTip = false
Vue.use(VueMeta);

new Vue({
  router,
  vuetify:Vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
