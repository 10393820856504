<template>
  <v-app class="main">
    <v-main>
      <Header></Header>
      <router-view></router-view>
      <Footer></Footer>
    </v-main>
  </v-app>
</template>
<script>

import Header from "./components/Header.vue";
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  metaInfo:{
    title:"Data Services API Tester"
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
</style>
