export default {
    name: "TableElement",

    props: ['index'],
    methods:{

        rowAction(){
            this.$emit('rowAction', this.index);

        },

    }


}
