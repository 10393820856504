<template>

    <div class="input-container">
      <v-row>
        <ApiInput
            v-for="(value, name) in inputsController.inputs"
            :key="name"
            :title="name"
            :label="value.label"
            :type="value.type"
            :default-value="value.value"
            :items="value.items"
            :mandatory="value.mandatory"
            :version="inputsController.inputs.apiVersion.value"
            :allowed-versions="value.versions"
            :visible="value.visible"
            :colSize="2"
            :disabled="value.disabled || isDemo"
            @apiInputChange="apiInputChange"


        ></ApiInput>

      </v-row>


      <v-row ref="componentCard">
        <v-col>
          <v-card v-if="isComponentSelected"

                  class="mx-auto"
                  max-width="400"
          >
            <v-row>
              <v-col cols="8">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5">
                      {{ selectedComponent.name.value }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-card-actions>
                  <v-btn
                      text
                      color="alertBlue"
                      @click="dismissComponentSelection"
                  >
                    Dismiss
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>

            <v-list class="transparent">
              <v-list-item
                  v-for="item in selectedComponent"
                  :key="item.label"
              >
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle class="text-right">
                  {{ item.value }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-btn
                  text
                  color="primary"
                  @click="executeComponentDataRequest"
              >
                Get Pin Data
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

    </div>

</template>

<script>

import ApiInput from "../../components/Layout/ApiInput";
import ResponseConfig from "./pinResponseConfig";
import dataResponseConfig from "./pinDataResponseConfig";

import {mapGetters} from "vuex";
import InputComponent from "../mixings/InputComponent.js";
import Api from '../../api/api.js'
import Systems from "../../api/Systems";

export default {
  name: "pinInputsComponent",
  components: {
    ApiInput,
  },
  mixins: [InputComponent],
  data() {
    return {
      apiVersionPath: 'pincomponents',
      apiComponentVersionPath: 'pincomponentdata',
      apiEndpoint: process.env.VUE_APP_DATA_SERVICES_API_URL,
      isComponentSelected: false,
      butonLabel: 'Check Component Availability',
      selectedComponent: {
        name: {
          label: 'Component',
          value: '',
        },
        id: {
          label: 'Id',
          value: '',
        },
        systemIdent: {
          label: 'System',
          value: '',
        },
        yearFrom: {
          label: 'Year From',
          value: '',
        },
        yearTo: {
          label: 'Year To',
          value: '',
        },
        engineCode: {
          label: 'Engine Code',
          value: '',
        }
      },
      inputsController: {
        inputs: {
          apiVersion: {
            label: 'Api Version',
            selected: true,
            value: 'V1',
            type: "select",
            items: ['V1'],
            mandatory: false,
            versions: ['V1'],
            visible: false,
            disabled: false,
          },
          ktype: {
            label: 'KType',
            selected: false,
            value: '',
            type: "number_input",
            items: [],
            mandatory: true,
            versions: ['V1'],
            visible: true,
            disabled: false,
          },
          system: {
            label: 'System',
            selected: true,
            value: Systems.labels[0],
            type: "dropdown",
            items: Systems.labels,
            mandatory: true,
            versions: ['V1'],
            visible: true,
            disabled: false,
          },
          language: {
            label: 'Language',
            selected: true,
            value: 'DE',
            type: "dropdown",
            items: ['DE', 'EN'],
            mandatory: true,
            versions: ['V1'],
            visible: true,
            disabled: true,
          },
          componentId: {
            label: 'Component ID',
            selected: false,
            value: '',
            type: "number_input",
            items: [],
            mandatory: false,
            versions: ['V1'],
            visible: true,
            disabled: false,
          },
          yearFrom: {
            label: 'Year From',
            selected: false,
            value: '',
            type: "date",
            items: [],
            mandatory: false,
            versions: ['V1'],
            visible: true,
            disabled: false,
          },
          yearTo: {
            label: 'Year To',
            selected: false,
            value: '',
            type: "date",
            items: [],
            mandatory: false,
            versions: ['V1'],
            visible: true,
            disabled: false,
          },
          engineCode: {
            label: 'Engine Code',
            selected: false,
            value: '',
            type: "input",
            items: [],
            mandatory: false,
            versions: ['V1'],
            visible: true,
            disabled: false,
          },
        },
      },
    };
  },

  watch: {},
  computed: {
    ...mapGetters(['getLoggedUser', 'getLoginStatus', 'getSessionToken']),
  },

  methods: {


    apiInputChange(title, selected, value) {
      this.inputsController.inputs[title].selected = selected;
      this.inputsController.inputs[title].value = value;
      this.enableRequest();
    },
    dismissComponentSelection() {
      this.isComponentSelected = false;
      let responseParsed = {
        error: false,
        errorMessage: '',
        responseTable: {},
        hideTable: true,
      }
      this.$emit('responseReady', responseParsed);
    },
    selectComponent(componentData) {
      const element = this.$refs.componentCard;
      const top = element.offsetTop;
      window.scrollTo(0, top);

      this.isComponentSelected = true;
      for (let i in componentData) {
        this.selectedComponent[i].value = componentData[i];
      }
    },
    setButtonLabel(label) {
      this.$emit('setButtonLabel', label);
    },
    executeRequest() {
      this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + this.apiVersionPath;
      this.isComponentSelected = false;

      let responseParsed = {
        error: false,
        errorMessage: '',
        responseTable: {},
        isSelectable: true,
        redirect:false

      }

      if (this.isDemo) {

        responseParsed.responseTable = this.parseResponse(JSON.parse(Api.mockedPinComponentRequest().body), ResponseConfig);
        this.$emit('responseReady', responseParsed);
      } else {


        fetch(this.apiEndpoint, {
          mode: "cors",
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'data-services-api-proxy-auth': this.getSessionToken
          },
          body: JSON.stringify({
            "body": JSON.stringify({
              "ktype": this.inputsController.inputs.ktype.value,
              "systemIdent": Systems.values[this.inputsController.inputs.system.value],
              "yearFrom": this.inputsController.inputs.yearFrom.value,
              "yearTo": this.inputsController.inputs.yearTo.value,
              "language": this.inputsController.inputs.language.value.toLowerCase(),
              "componentId": this.inputsController.inputs.componentId.value,
              "engineCode": this.selectedComponent.engineCode.value,
            })
          })

        }).then(
            (response) => {

              if (response.ok) {
                return response.json();
              } else {
                response.status = 500;
                response.errorMessage = 'Unknown error';
                return response;
              }
            }
        ).then(
            (data) => {

              if (data) {


                if (data.statusCode == 500) {
                  responseParsed.status = 500;
                  responseParsed.error = true;
                  responseParsed.errorMessage = JSON.parse(data.body).description;
                } else if (data.statusCode == 501) {
                  responseParsed.error = true;
                  responseParsed.errorMessage = data.body;
                } else if (data.statusCode == 200) {
                  responseParsed.responseTable = this.parseResponse(JSON.parse(data.body), ResponseConfig);
                } else {
                  responseParsed.error = true;
                  responseParsed.errorMessage = 'Unknown error';
                }


              }
              this.$emit('responseReady', responseParsed);
            }
        ).catch(error => {
          responseParsed.error = false;
          responseParsed.status = 500;
          responseParsed.errorMessage = 'There has been a problem with your fetch operation: ' + error+'. This error migth be caused because you do not have a valid session active or your IP is being whitelisted.';
          responseParsed.redirect=true;
          this.$emit('responseReady', responseParsed);
        });
      }


    },
    executeComponentDataRequest() {
      this.apiEndpoint = process.env.VUE_APP_DATA_SERVICES_API_URL + this.apiComponentVersionPath;

      let responseParsed = {
        error: false,
        errorMessage: '',
        responseTable: {},
        isSelectable: false,
        redirect:false
      }

      if (this.isDemo) {

        responseParsed.responseTable = this.parseResponse(JSON.parse(Api.mockedPinComponentDataRequest().body), dataResponseConfig);
        this.$emit('responseReady', responseParsed);
      } else {

        this.$emit('setLoadingStatus');
        fetch(this.apiEndpoint, {
          mode: "cors",
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'data-services-api-proxy-auth': this.getSessionToken
          },
          body: JSON.stringify({
            "body": JSON.stringify({
              "ktype": this.inputsController.inputs.ktype.value,
              "systemIdent": this.selectedComponent.systemIdent.value,
              "yearFrom": this.selectedComponent.yearFrom.value,
              "yearTo": this.selectedComponent.yearTo.value,
              "language": this.inputsController.inputs.language.value.toLowerCase(),
              "componentId": this.selectedComponent.id.value,
              "engineCode": this.selectedComponent.engineCode.value,
            })
          })

        }).then(
            (response) => {

              if (response.ok) {
                return response.json();
              } else {
                response.status = 500;
                response.errorMessage = 'Unknown error';
                return response;
              }
            }
        ).then(
            (data) => {

              if (data) {


                if (data.statusCode == 500) {
                  responseParsed.status = 500;
                  responseParsed.error = true;
                  responseParsed.errorMessage = JSON.parse(data.body).description;
                } else if (data.statusCode == 501) {
                  responseParsed.error = true;
                  responseParsed.errorMessage = data.body;
                } else if (data.statusCode == 200) {
                  responseParsed.responseTable = this.parseResponse(JSON.parse(data.body), dataResponseConfig);
                } else {
                  responseParsed.error = true;
                  responseParsed.errorMessage = 'Unknown error';
                }


              }


              this.$emit('responseReady', responseParsed);
            }
        ).catch(error => {
          responseParsed.redirect=false;

          responseParsed.error = true;
          responseParsed.status = 500;
          responseParsed.errorMessage = 'There has been a problem with your fetch operation: ' + error+'. This error migth be caused because you do not have a valid session active or your IP is being whitelisted.';
          console.error('There has been a problem with your fetch operation:', error);
          this.$emit('responseReady', responseParsed);
        });
      }
    }
  }
}
</script>

<style scoped>

.input-container {
  margin-left: 1em;
  margin-rigth: 1em;
  margin-top: 1em;
  padding-bottom: 2em;
}

.inputs-card {
  margin-left: 1em;
  margin-right: 1em;
}
</style>
