<template>
  <Layout
  :apiType="apiType"></Layout>
</template>

<script>



import Layout from "../components/Layout";

export default {
  name: "ApiTester",
  props:{
    apiType:String,
  },
  components: {Layout},
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Macs Api Tester',
  },
}
</script>

<style scoped>

</style>
