<template>
  <v-col class="api-input" :cols="title=='vin'?colSize+1:title=='dtcs'?colSize+2:colSize" v-if="visible && allowedVersions.includes(version)">
    <v-text-field v-if="type=='input'"
                  class="text-capitalize"
                  :label="label+''+(mandatory?'':' (Optional)')"
                  v-model="fieldValue"
                  @keyup="updateOnKeyUp"
                  :disabled="disabled"
                  autocomplete

    ></v-text-field>
    <v-text-field v-if="type=='number_input'"
                  class="text-capitalize"
                  :label="label+''+(mandatory?'':' (Optional)')"
                  v-model="fieldValue"
                  @keyup="updateOnKeyUp"
                  :disabled="disabled"
                  autocomplete
                  type="number"
    ></v-text-field>
    <v-menu
        v-if="type=='date'"
        ref="menuDate"
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="fieldValue"
            :label="label+''+(mandatory?'':' (Optional)')"
            hint="YYYY-MM-DD format"
            persistent-hint
            prepend-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            :disabled="disabled"

        ></v-text-field>
      </template>
      <v-date-picker
          v-model="fieldValue"
          no-title
          @input="dateMenu = false"
      ></v-date-picker>
    </v-menu>
    <v-select v-if="type=='select'"
              class="text-capitalize"
              :label="label+''+(mandatory?'':' (Optional)')"
              v-model="fieldValue"
              :items="itemsSorted"
              :disabled="disabled"
              @click="resetValue"
              :menu-props="{offsetY:true }"
    ></v-select>
    <v-combobox v-if="type=='dropdown'"
                class="text-capitalize"
                :label="label+''+(mandatory?'':' (Optional)')"
                v-model="fieldValue"
                :items="itemsSorted"
                @keyup="updateOnKeyUp"
                :menu-props="{ minWidth: '250' }"
                :disabled="disabled"

    ></v-combobox>
    <v-combobox v-if="type=='dropdown_noKeyUpUpdate'"
                class="text-capitalize"
                :label="label+''+(mandatory?'':' (Optional)')"
                v-model="fieldValue"
                :items="itemsSorted"
                :menu-props="{ minWidth: '250' }"
                :disabled="disabled"

    ></v-combobox>
    <v-combobox v-if="type=='multidropdown'"
                class="text-capitalize"
                :label="label+''+(mandatory?'':' (Optional)')"
                v-model="fieldValue"
                :items="itemsSorted"
                @keyup="updateOnKeyUp"
                :disabled="disabled"
    ></v-combobox>
    <v-combobox
        class="text-capitalize"
        v-if="type=='multiselect'"
        v-model="fieldValue"
        :items="itemsSorted"
        :label="label+''+(mandatory?'':' (Optional)')"
        small-chips
        multiple
        :disabled="disabled"
    >
      <template #selection="{ item }">
        <v-chip :color="item==fieldValue[fieldValue.length-1]?'primary':'secondary-darken-1'">{{ item }}</v-chip>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Add a code press <kbd>enter</kbd> to add a new one
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
    <v-radio-group
        v-if="type=='multiselect'"
        v-model="fieldValue"
    >
    </v-radio-group>
  </v-col>

</template>
<script>

export default {
  name: "api-input",
  props: ['persistedValue',"title", "type", "items", "mandatory", "default", "defaultValue", "version", "allowedVersions", "visible", "colSize", 'label', 'disabled','value'],
  data() {
    return {
      fieldValue: this.defaultValue,
      dateMenu:false
    };
  },
  computed: {
    itemsSorted() {
      return [...new Set(this.items)].sort()
    },
  },
  watch: {
    fieldValue(value) {
      this.$emit(
          "apiInputChange",
          this.title,
          value != null && value != "",
          this.type=='number_input'? parseInt(value):value
      );
    },
    value(value){
      this.fieldValue=value;
    }
  },
  methods: {
    updateOnKeyUp(event) {
      this.fieldValue = event.target.value;
    },
    resetValue(){
      this.fieldValue ='';

    }
  }
};
</script>


<style scoped>
.api-input {
  margin: 10px;
  display: flex;
}
.addButon {
  cursor: pointer;
}

.addButon:hover {
  color: #1D86D9 !important;
}

</style>
