import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/src/styles/main.sass'
import Vuetify from 'vuetify/lib/framework';
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'

Vue.use(Vuetify);

export default new Vuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        //dark: false,
        // light: false,
        //   colors: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        primary: '#22A059',
        primary_darken_1: '#005D2F',
        secondary: '#EDF8F1',
        secondary_darken_1: '#71C791',
        secondary_darken_2: '#6c8073',
        error: '#FF5252',
        info: '#1D86D9',
        success: '#4CAF50',
        warning: '#FB8C00',
        greyLighten5: '#FAFAFA',
        greyLighten2: '#E0E0E0',
        greenLighten5: '#EDF8F1',
        green: '#2AAF63',
        greenDarken4: '#005D2F',
        alertBlue: '#1D86D9'
        //    },
        //   variables: {}
      }
    }
  }
});
