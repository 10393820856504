<template>
  <div>

    <Content :apiType="apiType"></Content>
  </div>
</template>

<script>

import Content from "./Layout/AppContent.vue";
export default {
  name: "Layout",
  props:{
    apiType:String,
  },
  components: {

    Content,
  },
};
</script>

<style scoped>

</style>
