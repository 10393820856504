<template>
  <tr ref="rowStart" v-if="tableHeaders">
    <td @click="rowAction()" v-for="(tableCell,index_cell) in Object.values(filteredRow(indexToFilter))"
        :key="index_cell" height="300px"
        :class="{expanded:isExpanded, selected:isSelected}">
      <div class="dataCell"
           :class="{iconCell:tableHeaders[index_cell] && tableHeaders[index_cell].value=='classification'}"
           :style="{'height':isExpanded?(Math.max(Math.round(longestFieldLength/15)*30, 100))+'px':(tableHeaders[index_cell] && tableHeaders[index_cell].isHTML && tableCell.length>0)?'1000px':'100px'}"
      >
          <span v-if="tableHeaders[index_cell] && tableHeaders[index_cell].isHTML">

            <span v-if="tableCell.length>0" v-html="decodedHtml(tableCell)"/>
            <span v-else class="error-font-color">
              No image available
            </span>
          </span>
        <span v-else-if="tableHeaders[index_cell] && tableHeaders[index_cell].value.toLowerCase()=='classification'">
                <v-icon v-if="tableCell.trim().toLowerCase()=='critical'" color="error">mdi-alert-circle</v-icon>
                <v-icon v-if="tableCell.trim().toLowerCase()=='warning'" color="warning">mdi-alert</v-icon>
                <v-icon v-if="tableCell.trim().toLowerCase()=='info'" color="info">mdi-information</v-icon>
                 {{ tableCell }}
              </span>
        <span
            v-else-if="tableCell && tableHeaders[index_cell] && tableHeaders[index_cell].value.toLowerCase()=='error.description'"
            class="error-font-color">
                <v-icon color="error">mdi-alert-circle</v-icon>
                 {{ tableCell }}
              </span>
        <span v-else-if="typeof tableCell === 'number'">
               {{ tableCell }}
              </span>
        <span v-else>
                {{ tableCell.split('\\n').join('\n') }}
              </span>
      </div>
    </td>
  </tr>
</template>

<script>
import TableElement from "../mixings/TableElement";

export default {
  props: ['isExpanded', 'isSelected', 'row', 'tableHeaders', 'indexToFilter'],
  mixins: [TableElement],
  name: "TableRow",
  mounted() {


  },
  watch: {
    isSelected: function (newVal, oldVal) { // watch it


      if (newVal && !oldVal) {

        this.$emit('selectElement', this.index);
      }
    }
  },
  computed: {
    longestFieldLength() {
      let max = 0;
      for (let cell in this.row) {
        if (max < String(this.row[cell]).length) {
          max = String(this.row[cell]).length;
        }
      }
      return max;
    },

  },
  methods: {
    decodedHtml(base64String) {
      return atob(base64String);
    },
    filteredRow(index) {

      if (!index) {

        return this.row;
      }
      let filteredItems = [];
      for (let i in this.row) {
        if (i != index) {
          filteredItems.push(this.row[i]);
        }
      }
      for (let i in this.tableHeaders) {
        if (this.tableHeaders[i].value == index) {
          this.tableHeaders.splice(i, 1);
          i--;
        }

      }

      return filteredItems;

    }


  }
}
</script>

<style scoped>

.capitalHeader {
  text-transform: capitalize;
  text-align: left;
  padding: 5px;
  padding-left: 1.4em;
}

.dataCell {
  margin: 0.8em;
  margin-top: 5px;
  margin-rigth: 5px;
  text-align: left;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
 /* height: 100px;*/
  -webkit-line-clamp: 5; /* number of lines to show */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  transition: height 0.5s;
  white-space: pre-line;

}

.iconCell {
  text-align: left;
  text-transform: capitalize;
  min-width: 7em;
}

td.expanded .dataCell {

  overflow: hidden;
  -webkit-line-clamp: 20; /* number of lines to show */
  height: 900px;

}

td.expanded {
  background: rgba(116, 204, 149, 0.25);
}

.error-font-color {
  color: #FF5252;
}

</style>
